import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import DownloadIcon from '@mui/icons-material/Download';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  ExportDataAsCSV,
  convertTimeToTz,
} from '../CarrierExportTransactions/helpers';
import { listTransactionsByProgramService } from '../../queries';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { getProgramUuid } from '@/aggregator/config/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathPrivate } from '@/aggregator/paths/constants';
import { Mixpanel } from '@/aggregator/config/mixpanel';
import { defaultEndDate, defaultStartDate } from '@/aggregator/helpers';

const exportTransactions = async (
  programUuid: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const dupeStartDate = startDate ? new Date(startDate) : null;
  const dupeEndDate = endDate ? new Date(endDate) : null;
  const reportDate = format(new Date(), 'MM-dd-yyyy');

  const sDate =
    dupeStartDate !== null ? convertTimeToTz(dupeStartDate).toISOString() : '';
  const eDate =
    dupeEndDate !== null ? convertTimeToTz(dupeEndDate).toISOString() : '';

  listTransactionsByProgramService(programUuid, sDate, eDate)
    .then((response) => {
      ExportDataAsCSV({
        data: response.data.transactionRecords,
        columns: [
          { dataField: 'carrierId', text: 'Carrier Id' },
          { dataField: 'carrierName', text: 'Carrier Name' },
          { dataField: 'date', text: 'Date' },
          { dataField: 'time', text: 'Time' },
          { dataField: 'transactionNumber', text: 'Transaction Number' },
          { dataField: 'merchantId', text: 'Merchant Id' },
          { dataField: 'truckStopName', text: 'Truckstop Name' },
          { dataField: 'storeNumber', text: 'Store Number' },
          { dataField: 'locationId', text: 'Location ID' },
          { dataField: 'city', text: 'City' },
          { dataField: 'region', text: 'Region' },
          { dataField: 'productName', text: 'Product Name' },
          { dataField: 'productCode', text: 'Product Code' },
          { dataField: 'quantity.value', text: 'Quantity' },
          { dataField: 'unitRetailCost.value', text: 'Unit Retail Cost' },
          {
            dataField: 'unitDiscountedCost.value',
            text: 'Unit Discounted Cost',
          },
          { dataField: 'fee', text: 'Fee Amount' },
          {
            dataField: 'productRetailTotal.value',
            text: 'Product Retail Cost',
          },
          {
            dataField: 'productDiscountedTotal.value',
            text: 'Product Discounted Total',
          },
          {
            dataField: 'discountTotal.value',
            text: 'Discount Total',
          },
          {
            dataField: 'grandTotal',
            text: 'Grand Total',
          },
          { dataField: 'unitNumber', text: 'Unit Number' },
          { dataField: 'trailerNumber', text: 'Trailer Number' },
          { dataField: 'driverNumber', text: 'Driver Number' },
          { dataField: 'driverName', text: 'Driver Name' },
          { dataField: 'authorizationNumber', text: 'Auth Number' },
          { dataField: 'inNetworkIndicator', text: 'In Network Indicator' },
          { dataField: 'division', text: 'Division' },
          { dataField: 'tripNumber', text: 'Trip Number' },
          { dataField: 'odometer', text: 'Odometer' },
          { dataField: 'pumpNumber', text: 'Pump Number' },
          { dataField: 'billingTypeIndicator', text: 'Funding Type' },
          { dataField: 'marketingFee', text: 'Marketing Fee Total' },
          { dataField: 'programBilled', text: 'Program Billed Total' },
        ],
        fileName: `${reportDate}-transactions-report`,
        fromJson: true,
        omitHeaderRow: false,
      });
      toast.success('Transactions exported.');
    })
    .catch((e: any) => {
      if (e.response) {
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    });
};

const dateRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginY: 1,
  paddingX: 1,
};

export const CarrierExportAllProgramTransactions: FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const navigate = useNavigate();
  const location = useLocation();

  const programUuid = getProgramUuid() || '';

  const handleClose = () => {
    navigate(PathPrivate.Carriers);
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={() => {
        exportTransactions(programUuid, startDate, endDate)
          .then(() => {
            Mixpanel.track('report_exported', { location: location.pathname });
          })
          .catch((e) =>
            Mixpanel.track('report_export_failed', {
              location: location.pathname,
              error: e,
            }),
          );
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <Dialog open onClose={handleClose}>
          <DialogTitle>Export Program Transactions</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={dateRowStyle}>
                    <DatePicker
                      label="Start Date"
                      maxDate={endDate}
                      value={startDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ mb: 1, mr: 0.5 }}
                        />
                      )}
                    />
                    <TimePicker
                      label="Start Time"
                      value={startDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Box>
                  <Box sx={dateRowStyle}>
                    <DatePicker
                      label="End Date"
                      minDate={startDate}
                      value={endDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ mb: 1, mr: 0.5 }}
                        />
                      )}
                    />
                    <TimePicker
                      label="End Time"
                      value={endDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              <PrimaryButton type="submit" startIcon={<DownloadIcon />}>
                Export
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
