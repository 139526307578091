import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from 'react-router-dom';

import './config/axios.ts';

import * as auth from './config/auth';
import * as apollo from './config/apollo';

import { LayoutPublic } from './layout/LayoutPublic';
import { LayoutPrivate } from './layout/LayoutPrivate';

import { Root } from './pages/Root';
import { NotFound } from './pages/NotFound';
import { Login } from './pages/public/Login';
import { Logout } from './pages/public/Logout';
import { Denied } from './pages/public/Denied';

import { Dashboard } from './pages/private/dashboard';

import { Carriers } from './pages/private/carriers';
import { CarrierReadiness } from './pages/private/carriers/pages/CarrierReadiness';
import { CarrierBookPayment } from './pages/private/carriers/pages/CarrierBookPayment';
import { CarrierDrivers } from './pages/private/carriers/pages/CarrierDrivers';
import { CarrierDriversResetPassword } from './pages/private/carriers/pages/CarrierDriversResetPassword';
import { CarrierEdit } from './pages/private/carriers/pages/CarrierEdit';
import { CarrierCreditLine } from './pages/private/carriers/pages/CarrierCreditLine';
import { CarrierExportAllProgramTransactions } from './pages/private/carriers/pages/CarrierExportProgramTransactions';
import { CarrierExportTransactions } from './pages/private/carriers/pages/CarrierExportTransactions';
import { CarrierFleetAppUsers } from './pages/private/carriers/pages/CarrierFleetAppUsers';
import { CarrierFleetAppAddUser } from './pages/private/carriers/pages/CarrierFleetAppAddUser';
import { CarrierFleetAppUserResetPassword } from './pages/private/carriers/pages/CarrierFleetAppUserResetPassword';
import { CarrierLimits } from './pages/private/carriers/pages/CarrierLimits';
import { CarrierImportDrivers } from './pages/private/carriers/pages/CarrierImportDrivers';
import { CarrierPrompts } from './pages/private/carriers/pages/CarrierPrompts';
import { CarrierAddDriver } from './pages/private/carriers/pages/CarrierAddDriver';
import { CarrierProgramCreditDetails } from './pages/private/carriers/pages/CarrierProgramCreditDetails';
import { ProgramMarketingFees } from './pages/private/carriers/pages/ProgramMarketingFees';
import { CarrierMarketingFees } from './pages/private/carriers/pages/CarrierMarketingFees/';
import { CarrierPaymentHistory } from './pages/private/carriers/pages/CarrierPaymentHistory/index';

import { Merchants } from './pages/private/merchants';
import { MerchantLocations } from './pages/private/merchants/pages/MerchantLocations';

import { Users } from './pages/private/users';
import { AddUser } from './pages/private/users/pages/AddUser';
import { UserResetPassword } from './pages/private/users/pages/UserResetPassword';

import { LinkBankAccount } from './pages/private/bank';

import { PathPublic, PathPrivate } from './paths/constants';
import * as helpers from './paths/helpers';

function getRouter() {
  return createBrowserRouter([
    {
      path: PathPublic.Index,
      element: <Root />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          loader: () => helpers.ensureAuthAndRole(helpers.getInitialPath()),
        },
        {
          element: <LayoutPrivate isBasic />,
          loader: () => helpers.ensureAuth(),
          children: [
            {
              path: PathPrivate.LinkBankAccount,
              element: <LinkBankAccount />,
            },
          ],
        },
        {
          element: <LayoutPrivate />,
          loader: () => helpers.ensureAuthAndRole(),
          children: [
            {
              path: PathPrivate.Dashboard,
              element: <Dashboard />,
            },
            {
              id: PathPrivate.Carriers,
              path: PathPrivate.Carriers,
              element: <Carriers />,
              children: [
                {
                  path: PathPrivate.CarrierMarketingFees,
                  element: <CarrierMarketingFees />,
                },
                {
                  path: PathPrivate.CarrierReadiness,
                  element: <CarrierReadiness />,
                },
                {
                  path: PathPrivate.CarrierBookPayment,
                  element: <CarrierBookPayment />,
                },
                {
                  path: PathPrivate.CarrierPrompts,
                  element: <CarrierPrompts />,
                },
                {
                  path: PathPrivate.CarrierExportTransactions,
                  element: <CarrierExportTransactions />,
                },
                {
                  path: PathPrivate.CarrierEdit,
                  element: <CarrierEdit />,
                },
                {
                  path: PathPrivate.CarrierCreditLine,
                  element: <CarrierCreditLine />,
                },
                {
                  path: PathPrivate.CarrierExportAllTransactions,
                  element: <CarrierExportAllProgramTransactions />,
                },
                {
                  path: PathPrivate.CarrierLimits,
                  element: <CarrierLimits />,
                },
                {
                  path: PathPrivate.CarrierProgramCreditDetails,
                  element: <CarrierProgramCreditDetails />,
                },
                {
                  path: PathPrivate.ProgramMarketingFees,
                  element: <ProgramMarketingFees />,
                },
              ],
            },
            {
              path: PathPrivate.CarrierFleetAppUsers,
              element: <CarrierFleetAppUsers />,
              children: [
                {
                  path: PathPrivate.CarrierFleetAppAddUser,
                  element: <CarrierFleetAppAddUser />,
                },
                {
                  path: PathPrivate.CarrierFleetAppUserResetPassword,
                  element: <CarrierFleetAppUserResetPassword />,
                },
              ],
            },
            {
              path: PathPrivate.CarrierPaymentHistory,
              element: <CarrierPaymentHistory />,
            },
            {
              path: PathPrivate.CarrierDrivers,
              element: <CarrierDrivers />,
              children: [
                {
                  path: PathPrivate.CarrierDriversResetPassword,
                  element: <CarrierDriversResetPassword />,
                },
                {
                  path: PathPrivate.CarrierImportDrivers,
                  element: <CarrierImportDrivers />,
                },
                {
                  path: PathPrivate.CarrierAddDriver,
                  element: <CarrierAddDriver />,
                },
              ],
            },
            {
              id: PathPrivate.Merchants,
              path: PathPrivate.Merchants,
              element: <Merchants />,
            },
            {
              id: PathPrivate.MerchantLocations,
              path: PathPrivate.MerchantLocations,
              element: <MerchantLocations />,
            },
            {
              path: PathPrivate.Users,
              element: <Users />,
              children: [
                {
                  path: PathPrivate.UsersAddUser,
                  element: <AddUser />,
                },
                {
                  path: PathPrivate.UserResetPassword,
                  element: <UserResetPassword />,
                },
              ],
            },
          ],
        },
        {
          element: <LayoutPublic />,
          children: [
            {
              path: PathPublic.Login,
              element: <Login />,
              async loader() {
                if (auth.isLoggedIn()) {
                  return redirect(helpers.getInitialPath());
                }
                return null;
              },
            },
            {
              path: PathPublic.Logout,
              element: <Logout />,
            },
            {
              path: PathPublic.Denied,
              element: <Denied />,
            },
          ],
        },
      ],
    },
  ]);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <ApolloProvider client={apollo.client}>
        <RouterProvider router={getRouter()} />
      </ApolloProvider>
    </React.StrictMode>,
  );

auth.initKeycloak(renderApp);
