import { format } from 'date-fns';
import { usePapaParse } from 'react-papaparse';
import { getTransactionsService } from '../../queries';
import toast from 'react-hot-toast';
import { get } from 'lodash';

export const convertTimeToTz = (time: any) => {
  const tz = time?.getTimezoneOffset(); //get user timezone offset
  const utcTime = time?.setTime(
    //create utc time from timezone offset
    time.getTime() - tz * 60 * 1000,
  );

  const utcDate = new Date(utcTime); //create utc date object

  const tzTime = utcDate?.setTime(
    utcDate.getTime() + 5 * 60 * 60 * 1000, //create time from utcTime with 5 hours added for EST
  );

  const newTime = new Date(tzTime); //create date object from time

  return newTime;
};

type ExportDataProps = {
  data: any;
  columns: {
    dataField: string;
    text: string;
    formmatter?: any | undefined;
  }[];
  fileName: string;
  fromJson: boolean;
  omitHeaderRow: boolean;
};

export const ExportDataAsCSV = ({
  data,
  columns,
  fileName,
  fromJson,
  omitHeaderRow,
}: ExportDataProps): void => {
  const { jsonToCSV } = usePapaParse();

  const exportableData = data.map((line: any) => {
    const newLine: any = {};
    columns.map(
      (column: any) => (newLine[column.text] = get(line, column.dataField)),
    );
    return newLine;
  });
  const csvContent =
    fromJson === true
      ? jsonToCSV(exportableData, { header: !omitHeaderRow })
      : exportableData;

  const csvData = new Blob([`${csvContent}`], {
    type: 'text/csv;charset=utf-8;',
  });

  const csvURL = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL as string;
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
  link.remove();
};

export const exportTransactions = async (
  carrierUuid: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const dupeStartDate = startDate ? new Date(startDate) : null;
  const dupeEndDate = endDate ? new Date(endDate) : null;
  const reportDate = format(new Date(), 'MM-dd-yyyy');

  const sDate =
    dupeStartDate !== null ? convertTimeToTz(dupeStartDate).toISOString() : '';
  const eDate =
    dupeEndDate !== null ? convertTimeToTz(dupeEndDate).toISOString() : '';

  getTransactionsService(carrierUuid, sDate, eDate)
    .then((response) => {
      ExportDataAsCSV({
        data: response.data.transactionRecords,
        columns: [
          { dataField: 'date', text: 'Date' },
          { dataField: 'time', text: 'Time' },
          { dataField: 'transactionNumber', text: 'Transaction Number' },
          { dataField: 'merchantId', text: 'Merchant Id' },
          { dataField: 'truckStopName', text: 'Truckstop Name' },
          { dataField: 'storeNumber', text: 'Store Number' },
          { dataField: 'locationId', text: 'Location ID' },
          { dataField: 'city', text: 'City' },
          { dataField: 'region', text: 'Region' },
          { dataField: 'productName', text: 'Product Name' },
          { dataField: 'productCode', text: 'Product Code' },
          { dataField: 'quantity.value', text: 'Quantity' },
          { dataField: 'unitRetailCost.value', text: 'Unit Retail Cost' },
          {
            dataField: 'unitDiscountedCost.value',
            text: 'Unit Discounted Cost',
          },
          { dataField: 'tax', text: 'Tax' },
          { dataField: 'carrierFee', text: 'Fee Amount' },
          {
            dataField: 'productRetailTotal.value',
            text: 'Product Retail Cost',
          },
          {
            dataField: 'productDiscountedTotal.value',
            text: 'Product Discounted Total',
          },
          {
            dataField: 'discountTotal.value',
            text: 'Discount Total',
          },
          {
            dataField: 'grandTotal',
            text: 'Grand Total',
          },
          { dataField: 'unitNumber', text: 'Unit Number' },
          { dataField: 'trailerNumber', text: 'Trailer Number' },
          { dataField: 'driverNumber', text: 'Driver Number' },
          { dataField: 'driverName', text: 'Driver Name' },
          { dataField: 'authorizationNumber', text: 'Auth Number' },
          { dataField: 'inNetworkIndicator', text: 'In Network Indicator' },
          { dataField: 'division', text: 'Division' },
          { dataField: 'tripNumber', text: 'Trip Number' },
          { dataField: 'odometer', text: 'Odometer' },
          { dataField: 'pumpNumber', text: 'Pump Number' },
          { dataField: 'billingTypeIndicator', text: 'Funding Type' },
        ],
        fileName: `Onramp-${reportDate}-transactions-report`,
        fromJson: true,
        omitHeaderRow: false,
      });
      toast.success('Transactions exported.');
    })
    .catch((e: any) => {
      if (e.response) {
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    });
};
